<template>

<ul class="text-center">
  <li>
    <router-link to="/menunr1" class="hover:text-red-600">Example Nr.1</router-link>
  </li>
  <li>
    <router-link to="/menunr2" class="hover:text-red-600">Example Nr.2</router-link>
  </li>

  <li>
    <router-link to="/menunr3" class="hover:text-red-600">Example Nr.3</router-link>
  </li>

  <li>
    <router-link to="/menunr4" class="hover:text-red-600">Example Nr.4</router-link>
  </li>
</ul>

</template>

<script>




export default {
  name: "Menus"
}
</script>

<style scoped>

</style>